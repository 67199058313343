import(/* webpackMode: "eager" */ "/home/kimia/components/molecules/box/ribbon.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/appbar/main.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/sections/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kimia/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kimia/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager" */ "/home/kimia/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/kimia/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
