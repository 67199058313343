'use client'

import dynamic from 'next/dynamic'
import { Box, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const RibbonShapeAtom = dynamic(() => import('@/components/atoms/shapes/ribbon'))

const RibbonMolecule = () => {
	// Variables
	const { t } = useTranslation()

	return (
		<Box width={1} height={{ xs: 80, md: 110 }} mt={5} mb={-2.5} position="relative" overflow="hidden">
			<Box
				display="flex"
				justifyContent="center"
				width={1}
				height={{ xs: 40, md: 70 }}
				position="absolute"
				top="50%"
				left="50%"
				bgcolor={alpha('#000', 0.04)}
				overflow="hidden"
				zIndex={2}
				sx={{
					backdropFilter: 'blur(20px)',
					transformOrigin: 'center',
					transform: 'translate(-50%, -50%) rotate(-0.75deg)'
				}}
			>
				{Array.from(Array(20).keys()).map(index => (
					<Box
						key={index}
						display="inline-flex"
						flex="0 0 100px"
						flexWrap="nowrap"
						justifyContent="center"
						alignItems="center"
						sx={{
							px: 2,
							'& svg': {
								width: { xs: 16, md: 30 },
								height: { xs: 16, md: 30 }
							}
						}}
					>
						<Typography component="span" minWidth={{ xs: 70, md: 100 }} mr={1} fontSize={{ xs: 14, md: 18 }} fontWeight={700} color="common.white">
							{t('common:app.name')}
						</Typography>

						<RibbonShapeAtom />
					</Box>
				))}
			</Box>

			<Box
				width={1}
				height={{ xs: 38, md: 66 }}
				position="absolute"
				top="50%"
				left="50%"
				bgcolor="primary.dark"
				sx={{
					transformOrigin: 'center',
					transform: 'translate(-50%, -50%) rotate(0.65deg)'
				}}
			/>
		</Box>
	)
}

export default RibbonMolecule
