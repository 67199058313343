'use client'

import { useId } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { Grid2 as Grid, Box, Typography, Divider } from '@mui/material'
import { Apple as AppleIcon, Instagram as InstagramIcon, X as XIcon, Telegram as TelegramIcon, WhatsApp as WhatsAppIcon } from '@mui/icons-material'

// Types
import type { FooterProps } from '@/types/components/organisms/sections/footer'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const MenuItemsMolecule = dynamic(() => import('@/components/molecules/items/menu'))
const FooterAddressItemMolecule = dynamic(() => import('@/components/molecules/items/footer-address'))
const BlackButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/black'))
const ScrollToTopButtonIconAtom = dynamic(() => import('@/components/atoms/buttons/icons/scroll-to-top'))
const FooterIconButtonAtom = dynamic(() => import('@/components/atoms/buttons/icons/footer'))
const FotoerTitleTypographyAtom = dynamic(() => import('@/components/atoms/typographies/footer/title'))
const FotoerSubtitleTypographyAtom = dynamic(() => import('@/components/atoms/typographies/footer/subtitle'))
const FooterParagraphTypographyAtom = dynamic(() => import('@/components/atoms/typographies/footer/paragraph'))
const PlayStoreIconAtom = dynamic(() => import('@/components/atoms/icons/play-store'))
const LocationIconAtom = dynamic(() => import('@/components/atoms/icons/location'))
const PhoneIconAtom = dynamic(() => import('@/components/atoms/icons/phone'))
const EmailIconAtom = dynamic(() => import('@/components/atoms/icons/email'))

const FooterOrganism = (props: FooterProps) => {
	// Props
	const { items } = props

	// Varaibles
	const { t } = useTranslation()
	const id = useId()
	const menusNumber = items ? items.length : 0

	return (
		<Grid container p={2} pt={{ xs: 3, md: 5 }} bgcolor="#ebebeb" overflow="hidden">
			<Grid
				size={12}
				bgcolor="common.white"
				borderRadius={4}
				sx={{
					'& .MuiList-root': {
						p: 0,
						'& li': {
							display: { xs: 'flex', md: 'inline-block' },
							justifyContent: { xs: 'center', md: 'inherit' },
							width: 1,
							position: 'relative',
							mt: 0.5,
							borderRadius: 1,
							transition: 'all 0.3s ease',
							'& a': {
								display: 'inline-flex',
								py: 1,
								textTransform: 'none',
								transition: 'all 0.3s ease',
								color: 'inherit',
								'& .MuiTypography-root': {
									fontWeight: 400,
									fontSize: 14
								},
								'& .MuiListItemIcon-root': {
									minWidth: 24,
									'& .MuiSvgIcon-root': { transition: 'all 0.3s ease' }
								}
							}
						}
					}
				}}
			>
				<Grid container direction={{ xs: 'column', md: 'row' }} columns={12} spacing={{ xs: 1, md: 2, lg: 3 }} px={2}>
					<Grid size={{ xs: 12, md: 6, lg: 12 / Math.max(menusNumber + 2, 2) }} sx={{ order: { md: 10 } }}>
						<Grid container direction={{ xs: 'column', lg: 'row' }} alignItems={{ xs: 'center', md: 'inherit' }} spacing={2} height={{ md: 1 }}>
							<Grid size={12} mt={5}>
								<Typography component="span" fontSize={26.8} fontWeight={800} color="primary.dark">
									{t('common:app.name')}
								</Typography>
							</Grid>

							<Grid size={12}>
								<Grid container direction="column" alignItems={{ xs: 'center', md: 'start' }} spacing={2}>
									<Grid>
										<FotoerSubtitleTypographyAtom text="common:title.download" />
									</Grid>

									<Grid>
										<Link href="/">
											<BlackButtonAtom title="form:button.android" startIcon={<PlayStoreIconAtom />} />
										</Link>
									</Grid>

									<Grid>
										<Link href="/">
											<BlackButtonAtom title="form:button.ios" startIcon={<AppleIcon />} />
										</Link>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{items?.length &&
						items.map((menus, index) => {
							return (
								<Grid key={index} size={{ xs: 12, md: 4, lg: 12 / Math.max(menusNumber + 2, 2) }} mt={6}>
									{menus.length && menus[0].group && (
										<Grid container flexDirection="column" justifyContent="center" alignItems={{ xs: 'center', md: 'start' }} px={2}>
											<Typography width={{ md: 1 }} fontWeight={800}>
												{menus[0].group?.title}
											</Typography>

											<Divider sx={{ height: 10, width: '9rem' }} />
										</Grid>
									)}

									<MenuItemsMolecule items={menus} />
								</Grid>
							)
						})}

					<Grid size={{ xs: 12, md: 6, lg: 12 / Math.max(menusNumber + 2, 2) }} my={6} mb={{ md: 0 }}>
						<Grid container direction="column" alignItems={{ xs: 'center', md: 'start' }}>
							<Grid>
								<FotoerTitleTypographyAtom text="common:sample.footer.contactUs" />
							</Grid>

							<Divider sx={{ height: 10, width: '9rem' }} />

							<Grid my={2}>
								<FooterAddressItemMolecule title="common:sample.footer.address" paragraph="common:sample.footer.addressDetail" icon={<LocationIconAtom />} />
							</Grid>

							<Grid my={2}>
								<FooterAddressItemMolecule title="common:sample.footer.phoneNumber" paragraph="common:sample.footer.phoneNumberDetail" icon={<PhoneIconAtom />} />
							</Grid>

							<Grid my={2}>
								<FooterAddressItemMolecule title="common:sample.footer.email" paragraph="common:sample.footer.emailDetail" icon={<EmailIconAtom />} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid size={12}>
				<Grid container position="relative">
					<svg style={{ width: 0, height: 0, position: 'absolute', overflow: 'hidden' }}>
						<defs>
							<filter id={id}>
								<feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur" />
								<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9" result="goo" />
								<feComposite in="SourceGraphic" in2="goo" operator="atop" />
							</filter>
						</defs>
					</svg>

					<Box
						position="absolute"
						top={0}
						left="50%"
						zIndex={1}
						sx={{
							transform: 'translateX(-50%)',
							filter: `url("#${id}")`
						}}
					>
						<Box width={{ xs: 160, md: 208 }} height={{ xs: 64, md: 112 }} bgcolor="#ebebeb" />

						<Box
							width={{ xs: 100, md: 148 }}
							height={{ xs: 100, md: 148 }}
							position="absolute"
							top={-36}
							left="50%"
							bgcolor="#ebebeb"
							borderRadius={10}
							sx={{ transform: 'translateX(-50%)' }}
						/>
					</Box>

					<Box position="absolute" top={-24} left="50%" zIndex={2} sx={{ transform: 'translateX(-50%)' }}>
						<ScrollToTopButtonIconAtom onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
					</Box>
				</Grid>

				<Grid size={12} mt={{ xs: 8, md: 3 }} mb={{ xs: 1.5, md: 1 }}>
					<Grid container direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center">
						<Grid size={{ xs: 12, md: 'auto' }} textAlign={{ xs: 'center', md: 'inherit' }} zIndex={3}>
							<FooterParagraphTypographyAtom text="common:description.copyright" />
						</Grid>

						<Grid size={{ xs: 12, md: 'auto' }} mt={{ xs: 2, md: 'inherit' }} zIndex={3}>
							<Grid container justifyContent="center" alignItems="center" spacing={2}>
								<Grid>
									<FooterIconButtonAtom icon={<InstagramIcon />} label="Instagram" />
								</Grid>

								<Grid>
									<FooterIconButtonAtom icon={<TelegramIcon />} label="Telegram" />
								</Grid>

								<Grid>
									<FooterIconButtonAtom icon={<XIcon />} label="X" />
								</Grid>

								<Grid>
									<FooterIconButtonAtom icon={<WhatsAppIcon />} label="WhatsApp" />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default FooterOrganism
