'use client'

import { useState, MouseEvent } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { alpha } from '@mui/material/styles'
import { AppBar, Grid2 as Grid, Toolbar, Container } from '@mui/material'

// Types
import type { MainAppbarProps } from '@/types/components/organisms/appbar/main'

// Components
const HideOnScroll = dynamic(() => import('@/components/theme/hide-on-scroll'))
const SearchBoxMolecule = dynamic(() => import('@/components/molecules/box/search'))
const MenuItemsMolecule = dynamic(() => import('@/components/molecules/items/menu'))
const LogoShapeAtom = dynamic(() => import('@/components/atoms/shapes/logo'))
const MenuIconButtonAtom = dynamic(() => import('@/components/atoms/buttons/icons/menu'))
const CartMenuButtonAtom = dynamic(() => import('@/components/atoms/buttons/icons/cart-menu'))
const UserMenuButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/user-menu'))
const MenuTitleTypographyAtom = dynamic(() => import('@/components/atoms/typographies/menu/title'))
const PhoneAtom = dynamic(() => import('@/components/atoms/phone'))
const MenuIconAtom = dynamic(() => import('@/components/atoms/icons/menu'))
const CartIconAtom = dynamic(() => import('@/components/atoms/icons/cart'))
const UserMenuOrganism = dynamic(() => import('@/components/organisms/menu/user'))
const CartMenuOrganism = dynamic(() => import('@/components/organisms/menu/cart'))

const MainAppbarOrganism = (props: MainAppbarProps) => {
	// Props
	const { items } = props

	// Variables
	const [openSearch, setOpenSearch] = useState<boolean>(false)

	// User menu
	const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null)
	const openUserMenu = Boolean(anchorUserMenu)
	const handleClickUserMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorUserMenu(event.currentTarget)
	}
	const handleCloseUserMenu = () => {
		setAnchorUserMenu(null)
	}

	// Cart menu
	const [anchorCartMenu, setAnchorCartMenu] = useState<null | HTMLElement>(null)
	const openCartMenu = Boolean(anchorCartMenu)
	const handleClickCartMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorCartMenu(event.currentTarget)
	}
	const handleCloseCartMenu = () => {
		setAnchorCartMenu(null)
	}

	return (
		<HideOnScroll onlyMobile={!openSearch} onlyDesktop={true}>
			<AppBar
				sx={{
					color: 'inherit',
					bgcolor: alpha('#fff', 0.17),
					backdropFilter: 'blur(20px)',
					boxShadow: `0 0 23px ${alpha('#000', 0.085)}`,
					'@keyframes shake': {
						'0%': { transform: 'rotate(0deg)' },
						'25%': { transform: 'rotate(5deg)' },
						'50%': { transform: 'rotate(0eg)' },
						'75%': { transform: 'rotate(-5deg)' },
						'100%': { transform: 'rotate(0deg)' }
					}
				}}
			>
				<Toolbar sx={{ mt: 2, py: 1, px: 0 }}>
					<Container>
						<Grid container justifyContent="space-between">
							<Grid size={{ xs: 6, md: 'auto' }}>
								<Grid container alignItems="center" spacing={{ xs: 1.5, md: 1 }}>
									<Grid display={{ md: 'none' }}>
										<MenuIconButtonAtom icon={<MenuIconAtom />} />
									</Grid>

									<Grid display={{ xs: 'none', md: 'flex' }}>
										<Link href="/">
											<LogoShapeAtom />
										</Link>
									</Grid>

									<Grid>
										<Link href="/">
											<MenuTitleTypographyAtom text="common:app.name" />
										</Link>
									</Grid>
								</Grid>
							</Grid>

							<Grid size={{ xs: 6, md: 'auto' }} sx={{ order: { md: 3 } }}>
								<Grid container justifyContent="flex-end" spacing={1.5}>
									<Grid>
										<UserMenuButtonAtom title="form:button.login" onClick={handleClickUserMenu} onMouseEnter={handleClickUserMenu} />
										<UserMenuOrganism anchorUserMenu={anchorUserMenu} openUserMenu={openUserMenu} handleCloseUserMenu={handleCloseUserMenu} />
									</Grid>

									<Grid>
										<Link href="/cart">
											<CartMenuButtonAtom icon={<CartIconAtom />} onClick={handleClickCartMenu} onMouseEnter={handleClickCartMenu} />
											<CartMenuOrganism anchorCartMenu={anchorCartMenu} openCartMenu={openCartMenu} handleCloseCartMenu={handleCloseCartMenu} />
										</Link>
									</Grid>
								</Grid>
							</Grid>

							<Grid size={{ xs: 12, md: 6 }}>
								<SearchBoxMolecule open={openSearch} setOpen={setOpenSearch} />
							</Grid>

							<Grid size={12} display={{ xs: 'none', md: 'flex' }} mb={0.75} sx={{ order: { md: 4 } }}>
								<Grid container justifyContent="space-between" alignItems="center" width={1}>
									<Grid
										size="grow"
										sx={{
											'& .MuiList-root': {
												p: 0,
												'& li': {
													display: 'inline-block',
													position: 'relative',
													mr: { md: 0.25, lg: 3 },
													p: 0,
													transition: 'all 0.3s ease',
													borderRadius: 1,
													'& a': {
														display: 'inline-flex',
														alignItems: 'center',
														p: 1,
														textTransform: 'none',
														transition: 'all 0.3s ease',
														color: '#4f4f4f',
														'& .MuiTypography-root': {
															fontSize: 16,
															fontWeight: 400
														},
														'& .MuiListItemIcon-root': {
															minWidth: 16,
															color: 'common.black',
															'&:first-child': { mr: 1 },
															'&:last-child': { ml: 1 },
															'& .MuiSvgIcon-root': {
																fontSize: 16,
																transition: 'all 0.3s ease'
															}
														},
														'&:hover .MuiListItemIcon-root:first-child': {
															color: 'primary.dark',
															animation: 'shake 0.5s',
															animationIterationCount: 'infinite'
														}
													},
													'& ul': { display: 'none' }
												}
											}
										}}
									>
										<MenuItemsMolecule items={items} />
									</Grid>

									<Grid ml={1}>
										<PhoneAtom />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	)
}

export default MainAppbarOrganism
